import React from "react";

const OnlineApplication = ({ src, width, height }) => (
  <iframe
    title="Online Application"
    src={src}
    width={width}
    height={height}
  ></iframe>
);

export default OnlineApplication;
