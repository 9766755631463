import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";

import "./styles/aboutSideMenu.scss";

export default function AdmissionsOverviewSideMenu() {
  const data = useStaticQuery(graphql`
    query admissionOverviewMenuLinks {
      site {
        siteMetadata {
          admissionsOverviewMenu {
            link
            name
          }
        }
      }
    }
  `);
  return (
    <div id="admissionsOverviewSideMenu">
      <Row className="mt-md-5 mt-0 p-3 white-bg">
        <Col>
          <Nav
            defaultActiveKey={
              data.site.siteMetadata.admissionsOverviewMenu.link
            }
            className="flex-column"
          >
            <h2 className="menu-title">Graduate Admissions</h2>
            {data.site.siteMetadata.admissionsOverviewMenu.map((link) => (
              <Nav.Item>
                <Link to={link.link}>
                  <Nav.Link as="span" eventKey={link.link}>
                    <FontAwesomeIcon
                      icon={faChevronCircleRight}
                      color="#0078ae"
                    />
                    {link.name}
                  </Nav.Link>
                </Link>
              </Nav.Item>
            ))}
          </Nav>
        </Col>
      </Row>
    </div>
  );
}
